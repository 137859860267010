import React from "react";
import c from "classnames";
import { putAnswerFactory, addAnnoFactory } from "../../helpers/actionCreators";
import { Mode } from "../../constants";

const NumberButton = ({
  number,
  mode,
  onUpdate,
  focusedCell,
  cellState,
  annoState,
  history,
}) => (
  <div
    className={c(
      "rn-buttons-number",
      mode === Mode.ANSWER ? "rn-buttons-nomalColor" : "rn-buttons-annoColor"
    )}
    onClick={
      focusedCell
        ? mode === Mode.ANSWER
          ? putAnswerFactory({
              value: number,
              cell: focusedCell,
              cellState,
              history,
              onUpdate,
            })
          : addAnnoFactory({
              value: number,
              cell: focusedCell,
              annoState,
              history,
              onUpdate,
            })
        : () =>
            onUpdate({
              warning: "Select an empty cell first before deciding an number.",
            })
    }
  >
    {number}
  </div>
);

export default NumberButton;
