import React, { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "semantic-ui-react";
import "./PuzzlePage.css";
import { NumberPlace } from "../components/number-place";
import { Puzzle, Puzzles } from "../data/puzzles";
import { BackModal } from "../components/BackModal";
import { FinishedModal } from "../components/FinishedModal";
import { saveSolvedPuzzles } from "../helpers/localStorageHelper";

const formatLevel = (level: number) => {
  switch (level) {
    case 1:
      return "Easy";
    case 2:
      return "Normal";
    case 3:
      return "Hard";
    case 4:
      return "Very Hard";
    case 5:
      return "Unknown";
  }
};

export const PuzzlePage: FC = (props) => {
  const { puzzleId } = useParams<{ puzzleId: string }>();
  const [finished, setFinished] = useState(false);
  const [backOpen, setBackOpen] = useState(false);
  const puzzle = useMemo(
    () =>
      ([] as Puzzle[])
        .concat(...Object.values(Puzzles))
        .find((p) => p.puzzleId === puzzleId),
    [puzzleId]
  );
  const onFinished = useCallback(() => {
    saveSolvedPuzzles(puzzleId);
    setFinished(true);
  }, [puzzleId]);
  const onStartBack = useCallback(() => {
    setBackOpen(true);
  }, []);
  const onCancelBack = useCallback(() => {
    setBackOpen(false);
  }, []);
  if (!puzzle) {
    return <>Not Found</>;
  }
  return (
    <div className="puzzle-page-self">
      <Header as="h1" textAlign="center" color="grey">
        {formatLevel(puzzle.level)}
        {"#"}
        {puzzle.puzzleNumber}
      </Header>
      <div className="puzzle-page-back-wrap">
        <span className="puzzle-page-back-button" onClick={onStartBack}>
          {"<"} Back
        </span>
      </div>
      <NumberPlace
        className="puzzle-page-game"
        puzzleStr={puzzle.puzzleStr}
        onFinished={onFinished}
      />
      <FinishedModal open={finished} />
      <BackModal open={backOpen} onCancel={onCancelBack} />
    </div>
  );
};
