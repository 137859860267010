import "./App.css";
import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { SelectPage } from "./pages/SelectPage";
import { PuzzlePage } from "./pages/PuzzlePage";
import { NotFoundPage } from "./pages/NotFoundPage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <SelectPage />
        </Route>
        <Route path="/puzzles/:puzzleId" exact>
          <PuzzlePage />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
