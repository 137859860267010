import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Puzzle, Puzzles } from "../data/puzzles";
import { Container, Header, List } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import "./SelectPage.css";
import { restoreSolvedPuzzles } from "../helpers/localStorageHelper";

const PuzzleList: FC<{ puzzles: Puzzle[] }> = ({ puzzles }) => {
  const [solvedIds, setSolvedIds] = useState<string[]>([]);
  useEffect(() => {
    const puzzleIds = restoreSolvedPuzzles();
    setSolvedIds(puzzleIds);
  }, []);
  return (
    <List horizontal size="big">
      {puzzles.map(({ puzzleId, puzzleNumber }, index) => (
        <List.Item key={puzzleId} className="select-page-lite-item">
          <List.Header
            as={Link}
            to={`/puzzles/${puzzleId}`}
            className="select-page-list-item-header"
          >
            {"#"}
            {puzzleNumber}
            {solvedIds.includes(puzzleId) && (
              <Icon
                name="check circle"
                size="tiny"
                color="orange"
                className="select-page-badge"
              />
            )}
          </List.Header>
        </List.Item>
      ))}
    </List>
  );
};

export const SelectPage: FC = () => {
  return (
    <Container className="select-page-self">
      <Header as="h1" textAlign="center">
        NumberPlace Puzzles 100
      </Header>
      <Header as="h3">Easy</Header>
      <PuzzleList puzzles={Puzzles.easy} />
      <Header as="h3">Normal</Header>
      <PuzzleList puzzles={Puzzles.normal} />
      <Header as="h3">Hard</Header>
      <PuzzleList puzzles={Puzzles.hard} />
      <Header as="h3">Very Hard</Header>
      <PuzzleList puzzles={Puzzles.veryHard} />
      <Header as="h3" color="grey">
        About Developer
      </Header>
      <ul>
        <li>
          FujiHaruka (<a href="https://github.com">GitHub</a>)
        </li>
      </ul>
    </Container>
  );
};
