import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "semantic-ui-react";

export const FinishedModal: FC<{ open: boolean }> = ({ open }) => {
  const history = useHistory();
  const onEnd = useCallback(() => {
    history.replace("/");
  }, [history]);
  return (
    <Modal open={open} basic size="mini">
      <Modal.Header>Congratulations!</Modal.Header>
      <Modal.Content>The puzzle is finished.</Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onEnd}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
