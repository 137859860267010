import data from "./puzzles.json";

export type Puzzle = {
  puzzleId: string;
  puzzleStr: string;
  puzzleNumber: number;
  level: number;
};

export const Puzzles = {
  easy: data.easy as Puzzle[],
  normal: data.normal as Puzzle[],
  hard: data.hard as Puzzle[],
  veryHard: data.veryHard as Puzzle[],
};
