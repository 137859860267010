const SOLVED_PUZZLES_KEY = "sudoku.fuji.land:solved";

const uniq = (items: string[]): string[] => Array.from(new Set(items));

export const restoreSolvedPuzzles = (): string[] => {
  const str = window.localStorage.getItem(SOLVED_PUZZLES_KEY) || "[]";
  try {
    return JSON.parse(str) as string[];
  } catch (e) {
    return [];
  }
};

export const saveSolvedPuzzles = (puzzleId: string) => {
  const puzzleIds = restoreSolvedPuzzles();
  const newIds = uniq(puzzleIds.concat(puzzleId));
  window.localStorage.setItem(SOLVED_PUZZLES_KEY, JSON.stringify(newIds));
};
