export const Mode = {
  ANSWER: 1,
  ANNOTATION: 2,
};

export const HistoryType = {
  PUT_ANSWER: 1,
  PUT_ANNO: 2,
  DELETE: 3,
};
