import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "semantic-ui-react";

export const BackModal: FC<{ open: boolean; onCancel: () => void }> = ({
  open,
  onCancel,
}) => {
  const history = useHistory();
  const onOk = useCallback(() => {
    history.push("/");
  }, [history]);
  return (
    <Modal open={open} basic size="tiny">
      <Modal.Header>Back to Top</Modal.Header>
      <Modal.Content>
        Are you sure to go back to Top? Playing data will be lost.
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button negative onClick={onOk}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
