import React, { FC, useCallback, useMemo, useState } from "react";
import NumberPlaceInternal from "./numberplace";
import parseInitialProps from "./helpers/parseInitialProps";

export type NumberPlaceProps = {
  className?: string;
  onFinished: () => void;
  puzzleStr: string;
};

export const NumberPlace: FC<NumberPlaceProps> = ({
  className,
  onFinished,
  puzzleStr,
}) => {
  const initialState = useMemo(() => parseInitialProps(puzzleStr), [puzzleStr]);
  const [state, setState] = useState(initialState);
  const onUpdate = useCallback((next) => {
    setState((state) => ({ ...state, ...next }));
  }, []);
  return (
    <NumberPlaceInternal
      className={className}
      onFinished={onFinished}
      onUpdate={onUpdate}
      {...state}
    />
  );
};
